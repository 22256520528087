<!--begin::Main-->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div class="d-flex flex-column flex-root dashboardlist">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <ng-container>
        <!--begin::STATIC Aside-->
        <div
          #ktAside
          id="kt_aside"
          class="
            aside aside-light aside-hoverable aside-left
            d-flex
            flex-column flex-row-auto
          "
          [ngClass]="asideCSSClasses"
        >
          <!--begin::Brand-->
          <div
            class="brand flex-column-auto"
            id="kt_brand"
            [ngClass]="brandClasses"
          >
            <!--begin::Logo-->
            <a routerLink="/dashboardlist/{{ qformid }}" class="brand-logo">
              <img alt="Logo" [attr.src]="headerLogo" class="h-55px logo" />
            </a>
            <!--end::Logo-->

            <ng-container>
              <button
                class="
                  brand-toggle
                  btn btn-icon btn-active-light-primary
                  w-30px
                  h-30px
                  w-md-40px
                  h-md-40px
                "
                id="kt_aside_toggle"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Navigation/Angle-double-left.svg'
                  "
                  cacheSVG="true"
                  class="svg-icon svg-icon-xl"
                ></span>
              </button>
            </ng-container>
          </div>
          <!--end::Brand-->

          <!--begin::Aside Menu-->
          <div
            class="aside-menu-wrapper flex-column-fluid"
            id="kt_aside_menu_wrapper"
          >
            <ng-container *ngIf="disableAsideSelfDisplay">
              <!--begin::Header Logo-->
              <a routerLink="/dashboardlist/{{ qformid }}">
                <img alt="Logo" [attr.src]="headerLogo" />
              </a>
              <!--end::Header Logo-->
            </ng-container>
            <!--begin::Menu Container-->
            <div
              id="kt_aside_menu"
              class="aside-menu"
              [ngClass]="asideMenuCSSClasses"
              data-menu-vertical="1"
              [attr.data-menu-scroll]="asideMenuScroll"
              [attr.data-menu-dropdown]="asideMenuDropdown"
              data-menu-dropdown-timeout="500"
            >
              <div
                class="hover-scroll-overlay-y my-5 my-lg-5"
                id="kt_aside_menu_wrapper"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-wrappers="#kt_aside_menu"
                data-kt-scroll-offset="0"
              >
                <div
                  class="
                    menu
                    menu-column
                    menu-title-gray-800
                    menu-state-title-primary
                    menu-state-icon-primary
                    menu-state-bullet-primary
                    menu-arrow-gray-500
                  "
                  id="kt_aside_menu"
                  data-kt-menu="true"
                >
                  <ul class="menu-nav" [ngClass]="ulCSSClasses">
                    <!-- begin::1 Level -->
                    <li
                      class="menu-item"
                      aria-haspopup="true"
                      routerLinkActive="menu-item-active"
                    >
                      <a
                        href="javascript:void(0);"
                        class="drag-drawflow menu-link"
                      >
                        <span class="menu-title fs-4 align-middle menu-text"
                          >Workflow List</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::STATIC Aside-->
      </ng-container>
      <!-- end:: Aside Left -->

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!-- begin:: Header -->
        <div
          #ktHeader
          id="kt_header"
          class="header"
          [ngClass]="headerCSSClasses"
        >
          <!--begin::Container-->
          <div
            [ngClass]="headerContainerCSSClasses"
            class="d-flex align-items-stretch justify-content-center"
          >
            <ng-container *ngIf="loader$ | async as _loader">
              <ngb-progressbar
                class="loading-bar"
                height="3px"
                [value]="_loader"
              ></ngb-progressbar>
            </ng-container>

            <ng-container *ngIf="headerMenuSelfDisplay">
              <!--begin::Header Menu Wrapper-->
              <div
                class="header-menu-wrapper header-menu-wrapper-left"
                id="kt_header_menu_wrapper"
              >
                <ng-container *ngIf="!asideSelfDisplay">
                  <!--begin::Header Logo-->
                  <div class="header-logo">
                    <a routerLink="/">
                      <img alt="Logo" [attr.src]="headerLogo" />
                    </a>
                  </div>
                  <!--end::Header Logo-->
                </ng-container>
              </div>
              <!--end::Header Menu Wrapper-->
            </ng-container>

            <ng-container *ngIf="!headerMenuSelfDisplay">
              <div></div>
            </ng-container>

            <div class="topbar">topbar goes here</div>
          </div>
          <!--end::Container-->
        </div>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
          [ngClass]="contentClasses"
        >
          <ng-container *ngIf="!contentExtended">
            <div class="row">
              <!--begin::Entry-->
              <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container-xxl">
                  <!--begin::Row-->
                  <div class="row gy-5 g-xl-8">
                    <!--begin::Col-->
                    <div class="col-xxl-12">
                      <!--begin::Tables Widget 9-->
                      <div class="card card-xl-stretch mb-5 mb-xl-8">
                        <!--begin::Header-->
                        <div class="card-header border-0 pt-5">
                          <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1"></span>
                          </h3>
                          <div class="card-toolbar"></div>
                        </div>
                        <!--end::Header-->

                        <!--begin::Body-->
                        <div class="card-body py-3">
                          <form [formGroup]="addSNTable" class="hide">
                            <div class="form-group row">
                              <label
                                for="tablename"
                                class="col-sm-2 col-form-label"
                                >Table Name</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="tablename"
                                  id="tablename"
                                  placeholder="Table Name"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="tablecolumns"
                                class="col-sm-2 col-form-label"
                                >Table Columns</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="text"
                                  formControlName="tablecolumns"
                                  class="form-control"
                                  id="tablecolumns"
                                  placeholder="Table Columns"
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                for="phone"
                                class="col-sm-2 col-form-label"
                              ></label>
                              <div class="col-sm-4 text-center">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="saveTable()"
                                >
                                  Generate Table
                                </button>
                              </div>
                            </div>
                          </form>
                          <form [formGroup]="delSNTable" class="hide">
                            <div class="form-group row">
                              <label
                                for="tablename"
                                class="col-sm-2 col-form-label"
                                >Table Name</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="tablename"
                                  id="tablename"
                                  placeholder="Table Name"
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label
                                for="phone"
                                class="col-sm-2 col-form-label"
                              ></label>
                              <div class="col-sm-4 text-center">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="deleteTable()"
                                >
                                  Drop Table
                                </button>
                              </div>
                            </div>
                          </form>
                          <form [formGroup]="addServiceNow">
                            <div class="form-group row">
                              <label
                                for="staticEmail"
                                class="col-sm-2 col-form-label"
                                >Name</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="uname"
                                  id="staticEmail"
                                  placeholder="Full Name"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="address"
                                class="col-sm-2 col-form-label"
                                >Address</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="text"
                                  formControlName="uaddress"
                                  class="form-control"
                                  id="address"
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="email" class="col-sm-2 col-form-label"
                                >Email</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="email"
                                  formControlName="uemail"
                                  class="form-control"
                                  id="email"
                                  placeholder="email@domain.com"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="phone" class="col-sm-2 col-form-label"
                                >Phone Number</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="number"
                                  formControlName="uphone"
                                  class="form-control"
                                  id="phone"
                                  placeholder="Phone Number"
                                />
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="phone" class="col-sm-2 col-form-label"
                                >Upload Document</label
                              >
                              <div class="col-sm-4">
                                <input
                                  type="file"
                                  class="file-input"
                                  (change)="onFileSelected($event)"
                                  #fileUpload
                                />

                                <div class="file-upload mb-20">
                                  {{ fileName || "No file uploaded yet." }}

                                  <button
                                    mat-mini-fab
                                    color="primary"
                                    class="upload-btn"
                                    (click)="fileUpload.click()"
                                  >
                                    <mat-icon>attach_file</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="phone"
                                class="col-sm-2 col-form-label"
                              ></label>
                              <div class="col-sm-4 text-center">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  (click)="saveData()"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>

                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let data of servicenowdata;
                                  let i = index
                                "
                              >
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ data.u_first_name }}</td>
                                <td>{{ data.u_last_name }}</td>
                                <td>{{ data.u_email }}</td>
                                <td>{{ data.u_phone_number }}</td>
                                <td>
                                  <button
                                    class="btn-danger mr-5"
                                    (click)="EditRecord(data.u_external_id)"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    class="btn-danger"
                                    (click)="deleteRecord(data.u_external_id)"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!--begin::Body-->
                      </div>
                      <!--end::Tables Widget 9-->
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                  <!--end::Container-->
                </div>
              </div>
              <!--end::Entry-->
            </div>
          </ng-container>
          <!-- end:: Content Body -->
        </div>
        <!-- end:: Content -->
      </div>
      <!--end::Wrapper-->
    </div>
    <!-- end:: Body -->
    <!--end::Page-->
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class="d-flex flex-column flex-root">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<!--end::Main-->
