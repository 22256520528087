import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  HostListener,
} from "@angular/core";
import { SplashScreenService } from "./_qform/partials/layout/splash-screen/splash-screen.service";
import { Router, NavigationEnd, NavigationError } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { WorkFlowService } from "./services/work-flow.service";
@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];

  // @HostListener("window:message", ["$event"])
  // onMessage(e) {
  //   if (e.origin == environment.qformFrontEndUrl) {
  //     localStorage.setItem("wkfiHubToken", e.data);
  //   }
  // }

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    public apiService: WorkFlowService
  ) {}

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
