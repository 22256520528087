<div mat-dialog-title style="padding-left: 5px" class="mb-0">
  <div fxFlex fxLayout="row" class="row">
    <div fxFlex="80" style="padding-top: 2px" class="col-md-10">
      <h2 *ngIf="responseCode == 0" style="padding-left: 5px">
        {{ "Unsaved Changes" }}
      </h2>
    </div>
    <div fxFlex="20" fxLayoutAlign="end center" class="col-md-2">
      <button mat-icon-button (click)="onCancelClick()">
        <i class="fa fa-times-circle" style="font-size: 20px"></i>
      </button>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div
  fxLayout="column"
  style="padding-top: 10px"
  fxLayoutAlign="start center"
  class="mb-5"
>
  <div
    *ngIf="responseCode == 0"
    fxFlex="100"
    fxLayout="column"
    style="
      text-align: center;
      align-self: center;
      padding-top: 5px;
      width: 100%;
    "
  >
    <div style="font-size: 15px">
      <!--<b>{{ data.name }}</b>-->
    </div>
    <div>
      You have unsaved changes on the workflow stage. Do you want to continue?
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div>
  <div fxLayout="column" fxFlex>
    <div fxLayout="row" class="text-center mt-5">
      <button
        *ngIf="responseCode == 0"
        type="submit"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
        class="me-5"
      >
        {{ data.postivebtnLabel }}
      </button>
      <button
        type="submit"
        mat-raised-button
        color="warn"
        (click)="onCancelClick()"
      >
        {{ data.negativeBtnLabel }}
      </button>
    </div>
  </div>
</div>
