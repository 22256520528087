import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WorkFlowService } from "src/app/services/work-flow.service";

@Component({
  selector: "app-salesforce-modal",
  templateUrl: "./salesforce-modal.component.html",
  styleUrls: ["./salesforce-modal.component.scss"],
})
export class SalesforceModalComponent implements OnInit {
  form: FormGroup;
  sysid: any;
  uname: any;
  usite: any;
  uphone: any;
  utype: any;
  apiurl: any;
  accesstoken: any;
  apicolumns: any;
  data: any;
  instanceurl: any;

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<SalesforceModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      sysid,
      instanceurl,
      uname,
      usite,
      uphone,
      utype,
      apiurl,
      accesstoken,
      apicolumns,
    }
  ) {
    this.sysid = sysid;
    this.instanceurl = instanceurl;
    this.uname = uname;
    this.usite = usite;
    this.uphone = uphone;
    this.utype = utype;
    this.apiurl = apiurl;
    this.accesstoken = accesstoken;
    this.apicolumns = apicolumns;

    this.form = fb.group({
      sysid: [sysid],
      uname: [uname],
      usite: [usite],
      uphone: [uphone],
      utype: [utype],
    });
  }

  ngOnInit(): void {
    console.log(this.apiurl, this.accesstoken, this.apicolumns);
  }

  saveform() {
    let arrcolumns = this.apicolumns.split(",");
    const snowdata = {};

    arrcolumns.forEach((element) => {
      let icatcol = element.split("+");
      icatcol.forEach((ielement) => {
        if (ielement == "Salutation") {
          snowdata[ielement] = "Mr";
        }
        if (ielement == "FirstName") {
          let firstname = this.form.value.uname;
          firstname = firstname.split(" ");
          snowdata[ielement] = firstname[0];
        }
        if (ielement == "LastName") {
          let lastname = this.form.value.uname;
          lastname = lastname.split(" ");
          snowdata[ielement] = lastname[1];
        }
      });
      if (element == "Name") {
        snowdata[element] = this.form.value.uname;
      }
      if (element == "Site") {
        snowdata[element] = this.form.value.usite;
      }
      if (element == "Type") {
        snowdata[element] = this.form.value.utype;
      }
      if (element == "Phone") {
        snowdata[element] = this.form.value.uphone;
      }
      if (element == "Company") {
        snowdata[element] = this.form.value.utype;
      }
    });

    let apiurl = this.instanceurl + this.sysid + "?_HttpMethod=PATCH";
    let apiheader = '{"Authorization":"Bearer ' + this.accesstoken + '"}';
    this.api.updateSalesforce(apiurl, apiheader, snowdata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res == "" || res == null) {
          this.toastr.success(
            "Salesforce Record updated successfully",
            "Success"
          );
          this.dialogRef.close({ cancel: false, fetch: true });
        } else {
          this.toastr.error("Updating Salesforce failed! ", "Error!");
        }
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
