import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WorkFlowService } from "src/app/services/work-flow.service";

@Component({
  selector: "app-service-now-modal",
  templateUrl: "./service-now-modal.component.html",
  styleUrls: ["./service-now-modal.component.scss"],
})
export class ServiceNowModalComponent implements OnInit {
  form: FormGroup;
  sysid: any;
  uname: any;
  uaddress: any;
  uphone: any;
  uemail: any;
  apiurl: any;
  apiheader: any;
  apicolumns: any;
  data: any;

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ServiceNowModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    { sysid, uname, uaddress, uphone, uemail, apiurl, apiheader, apicolumns }
  ) {
    this.sysid = sysid;
    this.uname = uname;
    this.uaddress = uaddress;
    this.uphone = uphone;
    this.uemail = uemail;
    this.apiurl = apiurl;
    this.apiheader = apiheader;
    this.apicolumns = apicolumns;

    this.form = fb.group({
      sysid: [sysid],
      uname: [uname],
      uaddress: [uaddress],
      uphone: [uphone],
      uemail: [uemail],
    });
  }

  ngOnInit(): void {
    console.log(this.apiurl, this.apiheader, this.apicolumns);
  }

  saveform() {
    let apiurl = this.apiurl + "/" + this.sysid;
    let apiheader = this.apiheader.replace(/(\r\n|\n|\r)/gm, "");
    let arrcolumns = this.apicolumns.split(",");
    const snowdata = {};
    arrcolumns.forEach((element) => {
      if (element == "u_first_name") {
        snowdata[element] = this.form.value.uname;
      }
      if (element == "u_last_name") {
        snowdata[element] = this.form.value.uaddress;
      }
      if (element == "u_email") {
        snowdata[element] = this.form.value.uemail;
      }
      if (element == "u_phone_number") {
        snowdata[element] = this.form.value.uphone;
      }
    });

    this.api.updateServiceNow(apiurl, apiheader, snowdata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.result) {
          this.toastr.success(
            "ServiceNow Record updated successfully",
            "Success"
          );
          this.dialogRef.close({ cancel: false, fetch: true });
        } else {
          this.toastr.error("Saving ServiceNow failed! ", "Error!");
        }
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
