import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { WorkflowModel } from "../shared/workflowmodel";
import { ToastrService } from "ngx-toastr";
import { DashboardlistComponent } from "../_qform/partials/dashboardlist/dashboardlist.component";

@Injectable({
  providedIn: "root",
})
export class WorkFlowService {
  private apiUrl = environment.apiUrl;
  private qformUrl = environment.qformUrl;
  private baseurl = environment.baseUrl;
  private iHubBeUrl = environment.INTEGRATION_HUB_BACKEND_URL;
  workflowmodel: WorkflowModel[];
  cbsave: any;
  qformControls: any;
  access_token: any;
  objHubData: any = [];
  iHubConfigList: any = [];
  userEmail: any;
  sfObjectId: any;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      this.toastr.error(error.statusText, error.url);
      console.error(error);
      return of(result as T);
    };
  }

  getURLCleaned() {
    const url = location.hash;
    const URLpath = url.split("/");
    if (URLpath[1] === "/") {
      URLpath.splice(1, 1);
    }
    // console.log(URLpath);
    return URLpath;
  }

  setDeleteNodeName(nodename) {
    localStorage.setItem("nodename", nodename);
  }
  getDeleteNodeName() {
    return localStorage.getItem("nodename");
  }

  setUserId(userid) {
    localStorage.setItem("userid", userid);
  }
  getUserId() {
    return localStorage.getItem("userid");
  }

  getiHubToken() {
    return localStorage.getItem("wkfiHubToken");
  }

  setUnsaved(ustate) {
    localStorage.setItem("isUnsaved", ustate);
  }
  getUnsaved() {
    return localStorage.getItem("isUnsaved");
  }

  getFormId() {
    return localStorage.getItem("formid");
  }
  setFormId(formid) {
    localStorage.setItem("formid", formid);
  }

  /* Service Now Methods */

  getServiceNow(apiurl, headers) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .get(`${apiurl}`, { headers })
      .pipe(catchError(this.handleError("getServiceNow", [])));
  }

  saveServiceNow(apiurl, headers, data) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .post(`${apiurl}`, data, { headers })
      .pipe(catchError(this.handleError("saveServiceNow", [])));
  }

  updateServiceNow(apiurl, headers, data) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .put(`${apiurl}`, data, { headers })
      .pipe(catchError(this.handleError("updateServiceNow", [])));
  }

  saveServiceNowTable(data) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Access-Control-Allow-Origin", `${this.baseurl}`)
      .set("Authorization", "Basic " + btoa("admin" + ":" + "dq1X0mOxzPCV"));
    return this.http
      .post(`https://dev114543.service-now.com/api/695517/qformtable`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("saveServiceNowTable", [])));
  }

  deleteServiceNowTable(data) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Access-Control-Allow-Origin", `${this.baseurl}`)
      .set("Authorization", "Basic " + btoa("admin" + ":" + "dq1X0mOxzPCV"));
    return this.http.post(
      `https://dev114543.service-now.com/api/695517/qformdeletetable`,
      data,
      { headers }
    );
  }

  deleteServiceNow(sysid) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Access-Control-Allow-Origin", `${this.baseurl}`)
      .set("Authorization", "Basic " + btoa("admin" + ":" + "dq1X0mOxzPCV"));
    return this.http
      .delete(
        `https://dev114543.service-now.com/api/now/table/u_mudasir_table_label/${sysid}`,
        { headers }
      )
      .pipe(catchError(this.handleError("deleteServiceNow", [])));
  }

  /* Service Now Ends here */

  /* Salesforce Methods starts here */

  getSalesforcetoken(apiurl) {
    return this.http
      .post(`${apiurl}`, "")
      .pipe(catchError(this.handleError("getSalesforcetoken", [])));
  }

  getSalesforceData(apiurl, headers) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .get(`${apiurl}`, { headers })
      .pipe(catchError(this.handleError("getSalesforceData", [])));
  }

  saveSalesforce(apiurl, headers, data) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .post(`${apiurl}`, data, { headers })
      .pipe(catchError(this.handleError("saveSalesforce", [])));
  }

  updateSalesforce(apiurl, headers, data) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .post(`${apiurl}`, data, { headers })
      .pipe(catchError(this.handleError("updateSalesforce", [])));
  }

  deleteSalesforce(apiurl, headers) {
    if (headers != "") {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    return this.http
      .delete(`${apiurl}`, { headers })
      .pipe(catchError(this.handleError("deleteSalesforce", [])));
  }

  /* Salesforce Methods ends here */

  getformNamefromQForms(formId) {
    return this.http
      .get(`${this.qformUrl}/get-form/${formId}`)
      .pipe(catchError(this.handleError("qformformname", [])));
  }

  getformNamefromQFormsOnline(formId) {
    return this.http
      .get(`${this.qformUrl}/get-form/${formId}`)
      .pipe(catchError(this.handleError("qformformname", [])));
  }

  generateWorkflowData(workflowid) {
    return this.http
      .get(`${this.apiUrl}/workflowdata/${workflowid}`)
      .pipe(catchError(this.handleError("workflowDataList", [])));
  }
  generatePublishWorkflowData(workflowid) {
    return this.http
      .get(`${this.apiUrl}/publishWorkflowdata/${workflowid}`)
      .pipe(catchError(this.handleError("publishWorkflowDataList", [])));
  }

  generatePublishWorkflowStages(formid, entryid) {
    return this.http
      .get(
        `${this.qformUrl}/get-stages-for-workflow/${formid}/${entryid}/qforms`
      )
      .pipe(catchError(this.handleError("publishWorkflowDataList", [])));
  }

  generateWorkflowOnId(workflowid, formid) {
    return this.http
      .get(`${this.apiUrl}/workflowOnId/${workflowid}`)
      .pipe(catchError(this.handleError("workflowOnIdDataList", [])));
  }

  getPublishedWorkflowApproval(formid) {
    return this.http
      .get(
        `${this.qformUrl}/get-workflow-approvals-details-by-formid/${formid}`
      )
      .pipe(catchError(this.handleError("getPublishedWorkflowApproval", [])));
  }

  getWorkflowList(userid: any, formid: any) {
    return this.http
      .get(`${this.apiUrl}/get-workflow-list/${userid}/${formid}`)
      .pipe(catchError(this.handleError("workflowDataList", [])));
  }

  gePublishedtWorkflowList(userid: any, formid: any) {
    try {
      return this.http
        .get(`${this.apiUrl}/get-workflow-list-published/${userid}/${formid}`)
        .pipe(catchError(this.handleError("workflowDataList", [])));
    } catch (e) {}
  }

  WorkflowExists(workflowname: any, formid: any) {
    try {
      return this.http
        .get(`${this.apiUrl}/get-workflow-exists/${workflowname}/${formid}`)
        .pipe(catchError(this.handleError("WorkflowExists", [])));
    } catch (e) {}
  }

  setpublished(data, workflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/workflow-published/${workflowId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("setpublished", [])));
  }

  setCheckedOut(data, workflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/workflow-checkedout/${workflowId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("setCheckedOut", [])));
  }

  saveWorkFlow(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-workflow`, data, { headers })
      .pipe(catchError(this.handleError("saveWorkFlow", [])));
  }
  saveWorkFlowStages(data, workflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-workflow-stages/${workflowId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("saveWorkFlowStages", [])));
  }

  sendWorkflowForApproval(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.qformUrl}/save-workflow-approval`, data, { headers })
      .pipe(catchError(this.handleError("sendWorkflowForApproval", [])));
  }
  publishWorkFlow(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/publish-workflow`, data, { headers })
      .pipe(catchError(this.handleError("publishWorkFlow", [])));
  }

  copyWorkFlow(data, formmode) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/copy-workflow/${formmode}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("copyWorkFlow", [])));
  }

  updateWorkflowdata(data, workflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-workflow-data/${workflowId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateWorkflowdata", [])));
  }

  updateWorkflow(data, workflowid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-workflow/${workflowid}`, data, { headers })
      .pipe(catchError(this.handleError("updateWorkflow", [])));
  }

  deleteWorkflow(id, workflowId, deletemode) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(
        `${this.apiUrl}/delete-workflow/${id}/${workflowId}/${deletemode}`,
        {},
        { headers }
      )
      .pipe(catchError(this.handleError("deleteWorkflow", [])));
  }

  getCheckHistory(workflowid) {
    try {
      return this.http
        .get(`${this.apiUrl}/get-workflow-history/${workflowid}`)
        .pipe(catchError(this.handleError("getCheckHistory", [])));
    } catch (e) {}
  }

  getHistoryList(recid, formid, workflowid, currentPage, pageSize) {
    try {
      return this.http
        .get(
          `${this.apiUrl}/get-workflow-history-list/${recid}/${formid}/${workflowid}/${pageSize}?page=${currentPage}`
        )
        .pipe(catchError(this.handleError("workflowHistoryList", [])));
    } catch (e) {}
  }

  deleteNode(workflowId, nodeid, nodename) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(
        `${this.apiUrl}/delete-node/${workflowId}/${nodeid}/${nodename}`,
        {},
        { headers }
      )
      .pipe(catchError(this.handleError("deleteNode", [])));
  }

  /**Begin Rule Methods */
  getBeginDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/begin-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getBeginDetails", [])));
  }
  saveBeginFlow(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-beginflow`, data, { headers })
      .pipe(catchError(this.handleError("saveBeginFlow", [])));
  }
  updateBeginflowdata(data, beginflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-beginflow/${beginflowId}`, data, { headers })
      .pipe(catchError(this.handleError("updateBeginflowdata", [])));
  }

  /* Approval Rule Methods */
  getApprovalDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/approval-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getApprovalDetails", [])));
  }
  getControls(formID) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application-json");
    return this.http
      .get(`${this.qformUrl}/get-form/${formID}`, { headers })
      .pipe(catchError(this.handleError("getControls", [])));
  }
  getControlMappingsForRules() {
    return this.http
      .get(`${this.apiUrl}/control-mappings`)
      .pipe(catchError(this.handleError("getControlMappingsForRules", [])));
  }
  saveApproval(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-approval`, data, { headers })
      .pipe(catchError(this.handleError("saveApproval", [])));
  }
  updateApproval(data, approvalid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-approval/${approvalid}`, data, { headers })
      .pipe(catchError(this.handleError("updateApproval", [])));
  }
  getUserlist(formID) {
    return this.http
      .get(`${this.qformUrl}/user-list-by-form-id/${formID}`)
      .pipe(catchError(this.handleError("getUserlist", [])));
  }
  getUserGroups(formID) {
    return this.http
      .get(`${this.qformUrl}/user-roles-by-form-id/${formID}`)
      .pipe(catchError(this.handleError("getUserGroups", [])));
  }

  /*Assign Rule Methods */
  getAssignDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/assign-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getAssignDetails", [])));
  }
  saveAssign(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-assign`, data, { headers })
      .pipe(catchError(this.handleError("saveAssign", [])));
  }
  updateAssign(data, assignid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-assign/${assignid}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateAssign", [])));
  }

  /*Send Email Rule Methods */
  getEmailDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/email-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getEmailDetails", [])));
  }
  saveEmail(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-email`, data)
      .pipe(catchError(this.handleError("saveEmail", [])));
  }
  updateEmail(data, sendemailid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-email/${sendemailid}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateEmail", [])));
  }

  /*Send Approval Report */
  getappRptDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/appRpt-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getappRptDetails", [])));
  }
  saveappRpt(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-appRpt`, data)
      .pipe(catchError(this.handleError("saveappRpt", [])));
  }
  updateappRpt(data, apprptid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-appRpt/${apprptid}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateappRpt", [])));
  }

  /*Conditional Branch Rule Methods */
  getCondBranchDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/conditional-branch-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getCondBranchDetails", [])));
  }
  saveCondBranch(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-conditional-branch`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("saveCondBranch", [])));
  }
  updateCondBranch(data, condbranchid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-conditional-branch/${condbranchid}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateCondBranch", [])));
  }

  /*If Else Rule Methods */
  getIfElseDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/ifelse-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getIfElseDetails", [])));
  }
  saveIfElse(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-ifelse`, data, { headers })
      .pipe(catchError(this.handleError("saveIfElse", [])));
  }
  updateIfElse(data, ifelseid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-ifelse/${ifelseid}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateIfElse", [])));
  }

  /**Merge Branches Rule Methods */
  getMergeBranchesDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/merge-branches-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getMergeBranchesDetails", [])));
  }
  saveMergeBranches(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-merge-branches`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("saveMergeBranches", [])));
  }

  updateMergeBranches(data, mergebranchesId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-merge-branches/${mergebranchesId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateMergeBranches", [])));
  }

  /**Split Rule Methods */
  getSplitDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/split-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getSplitDetails", [])));
  }
  saveSplit(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-split`, data, { headers })
      .pipe(catchError(this.handleError("saveSplit", [])));
  }

  updateSplit(data, splitId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-split/${splitId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateSplit", [])));
  }

  /**RunScript Rule Methods */
  getRunScriptDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/runscript-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getRunScriptDetails", [])));
  }
  saveRunScript(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-runscript`, data, { headers })
      .pipe(catchError(this.handleError("saveRunScript", [])));
  }

  /**Integration Hub Rule Methods */

  IntegrationHubConfiguration(_access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + _access_token,
      }),
    };
    return this.http.get(`${this.iHubBeUrl}/configuration/get`, httpOptions);
  }
  getHubAccounts(objUser, access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + access_token,
      }),
    };
    return this.http.post(
      `${this.iHubBeUrl}/accounts/getAccount/`,
      objUser,
      httpOptions
    );
  }

  getForm(fId) {
    return this.http.get(`${this.qformUrl}/get-form/${fId}`);
  }

  getObjects(formId, userId, accountId) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.getiHubToken(),
      }),
    };
    return this.http.get(
      `${this.iHubBeUrl}/integration/getObjects/` +
        formId +
        `/` +
        userId +
        `/` +
        accountId,
      httpOptions
    );
  }

  getHubColumns(formId, userId, _access_token, hubName, accountId) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + _access_token,
      }),
    };
    return this.http.get(
      `${this.iHubBeUrl}/integration/getObjectsOnName/` +
        formId +
        `/` +
        userId +
        `/` +
        hubName +
        `/` +
        accountId,
      httpOptions
    );
  }

  getIntegrationHubDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/integrationhub-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getIntegrationHubDetails", [])));
  }
  saveIntegrationHub(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-integrationhub`, data, { headers })
      .pipe(catchError(this.handleError("saveIntegrationHub", [])));
  }

  updateIntegrationHub(data, splitId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-integrationhub/${splitId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateIntegrationHub", [])));
  }

  updateRunScript(data, runscriptId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-runscript/${runscriptId}`, data, { headers })
      .pipe(catchError(this.handleError("updateRunScript", [])));
  }

  apiGetResult(apimethod, apiurl, headers, apiparams) {
    if (headers != null) {
      headers = headers.replace(/(\r\n|\n|\r)/gm, "");
      headers = JSON.parse(headers);
    }
    if (apimethod === "get") {
      return this.http
        .get(`${apiurl}`, {
          headers: headers,
          observe: "response",
          params: apiparams,
        })
        .pipe(catchError(this.handleError("RunScriptGetApiDetails", [])));
    }
    if (apimethod === "post") {
      return this.http
        .post(`${apiurl}`, apiparams, { headers })
        .pipe(catchError(this.handleError("RunScriptPostApiDetails", [])));
    }
  }

  /**End Flow Rule Methods */
  getEndflowDetails(workflowid, nodeid) {
    return this.http
      .get(`${this.apiUrl}/endflow-details/${workflowid}/${nodeid}`)
      .pipe(catchError(this.handleError("getEndFlowDetails", [])));
  }
  saveEndFlow(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/save-endflow`, data, { headers })
      .pipe(catchError(this.handleError("saveEndFlow", [])));
  }

  updateEndFlow(data, endflowId) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.apiUrl}/update-endflow/${endflowId}`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateEndFlow", [])));
  }

  dateformatter(date) {
    return new Date(date - 1000);
  }

  updateQFormWithWorkflow(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(`${this.qformUrl}/update-form-workflow`, data, {
        headers,
      })
      .pipe(catchError(this.handleError("updateQFormWithWorkflow", [])));
  }
}
