<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>&nbsp;</h2>
</div>

<mat-dialog-content>
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border">
      <button
        (click)="close()"
        title=""
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-3">
        <div class="mb-3 mb-md-0 fw-bold pt-2">
          <h4 class="text-gray-900 fw-bolder">
            History of - {{ workflowname }}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div
      fxLayout="column"
      [ngStyle]="{ 'margin-left': '15px', 'margin-right': '15px' }"
      class="top-padding mb-30"
    >
      <div class="example-container">
        <table
          mat-table
          [dataSource]="dataSource"
          #mattable
          *ngIf="TABLE_DATA?.length > 0; else no_content"
        >
          <ng-container matColumnDef="userNumber">
            <th mat-header-cell *matHeaderCellDef>Version No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.userNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>WORKFLOW NAME</th>
            <td mat-cell *matCellDef="let element">
              <a
                href="#/workstage/{{ formid }}/{{ element._id }}/publish"
                class="text-dark text-hover-primary fs-6"
                target="_blank"
                >{{ element.name }}</a
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="publishedby">
            <th mat-header-cell *matHeaderCellDef>PUBLISHED BY</th>
            <td mat-cell *matCellDef="let element">
              {{ element.publishedby }}
            </td>
          </ng-container>
          <ng-container matColumnDef="publishedon">
            <th mat-header-cell *matHeaderCellDef>PUBLISHED ON</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.ispublished == false
                  ? (element.publishedon | date: "MMM dd, yyyy hh:mm a")
                  : ""
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ispublished">
            <th mat-header-cell *matHeaderCellDef>PUBLISHED</th>
            <td mat-cell *matCellDef="let element">
              {{ element.ispublished }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <ng-template #no_content>
        <ng-container>
          <h6>Workflow History not found!</h6>
        </ng-container>
      </ng-template>
      <mat-paginator
        #matpaginator
        showFirstLastButtons
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="handlePage($event)"
      >
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
</mat-dialog-actions>
