<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="sysid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        title=""
        class="
          btn btn-icon btn-sm btn-default
          icon-chevron-left
          navbar-btn
          mt-1
        "
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-3">
        <div class="mb-3 mb-md-0 fw-bold pt-2">
          <h4 class="text-gray-900 fw-bolder">Edit Service Now Records</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="uname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Site</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Site</mat-label>
            <input matInput formControlName="usite" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Type</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput formControlName="utype" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Phone</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="uphone" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>

  <a
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform()"
    >Update</a
  >
</mat-dialog-actions>
