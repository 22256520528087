<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <input hidden matInput formControlName="nodeid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        title=""
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-3">
        <div class="mb-3 mb-md-0 fw-bold pt-2">
          <h4 class="text-gray-900 fw-bolder">
            Workflow Activity -
            {{ description }} for [ {{ formname }} ]
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Integration Name</mat-label>
            <input matInput formControlName="workflowname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
  </div>

  <div class="modal-body scroll-y mx-5 pt-0 pb-15">
    <div class="col-md-12 card pt-0 mb-xl-9">
      <div class="d-flex flex-column fv-row mt-8">
        <div class="row">
          <div class="col-xs-12"></div>
        </div>
      </div>
      <div *ngFor="let config of configList">
        <div class="row" *ngIf="hasColumns(config.item)">
          <div class="col-xs-12 mb-10">
            <h1 class="hub-Heading">{{ config.item.hubName }}</h1>
            <div class="list">
              <div *ngFor="let list of hubData">
                <div class="item" *ngIf="list.accountType == 'create'">
                  <div
                    aria-checked="false"
                    aria-disabled="false"
                    aria-label="Account"
                    role="checkbox"
                    tabindex="0"
                    class="list-item"
                  >
                    <span aria-hidden="true" role="img" class="spn-listitem">
                      <span class="spn-input">
                        <span class="spn-input-inner">
                          <input
                            type="checkbox"
                            class="checkbox-list"
                            (change)="onHubSelection($event)"
                            value="{{ list._id }}"
                            [checked]="list.isChecked"
                          />
                        </span>
                      </span>
                      <div class="div-listitem">
                        <img
                          alt="logo"
                          height="30"
                          src="../../../assets/media/images/social/{{
                            config.item.hubName | lowercase
                          }}.png"
                          width="30"
                          style="border-radius: 0px"
                        />{{ list.apiObjectLabel }}
                        <span class="spn-type-main">
                          <span class="spn-type-pill">
                            <span
                              data-color="gray"
                              data-size="small"
                              class="spn-type-label"
                            >
                              {{ list.accountType | titlecase }} a Record
                            </span>
                          </span>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="beginid == 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('integrationhub', 'save')"
    >Save</a
  >
  <a
    *ngIf="beginid != 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('integrationhub', 'update')"
    >Update</a
  >
</mat-dialog-actions>
