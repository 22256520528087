<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <input hidden matInput formControlName="nodeid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        (click)="close()"
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div class="mb-md-0 fw-bold">
          <h4 class="text-gray-900 fw-bolder">
            Workflow Activity -
            {{ description }} for [ {{ formname }} ]
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 required">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Approval Name</mat-label>
            <input matInput formControlName="workflowname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Stage</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Approval Stage</mat-label>
            <input matInput formControlName="workflowstage" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="row mb-8">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title
                ><h2 class="accordion-header fw-bold acc_button">
                  Assignee
                </h2></mat-panel-title
              >
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-xs-12">
                <table style="width: 100%" class="" role="presentation">
                  <tbody>
                    <tr
                      class="annotation-row"
                      data-annotation-type="Info Box Blue"
                      style="display: table-row"
                    >
                      <td
                        colspan="99"
                        class="annotation"
                        data-annotation-type="Info Box Blue"
                      >
                        <div
                          class="annotation-wrapper"
                          style="padding: 2px 0px 4px 4px"
                          role="note"
                        >
                          Specify the users whose approval will be requested.
                          Each user will be assigned an approval record.
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <mat-radio-group>
                <div class="col-sm-12 mb-5 mt-3">
                  <mat-radio-button
                    value="groups"
                    (change)="radioChange($event)"
                    [checked]="groupapp"
                    name="group"
                  >
                    <div class="text-left">
                      <label class="form-label fw-bold">Groups</label>
                    </div></mat-radio-button
                  >
                  <mat-radio-button
                    value="users"
                    (change)="radioChange($event)"
                    [checked]="userapp"
                    name="group"
                  >
                    <div class="text-left">
                      <label class="form-label fw-bold">Users</label>
                    </div></mat-radio-button
                  >
                  <mat-radio-button
                    value="others"
                    (change)="radioChange($event)"
                    [checked]="isothers"
                    name="group"
                  >
                    <div class="text-left">
                      <label class="form-label fw-bold">Others</label>
                    </div></mat-radio-button
                  >
                </div>
              </mat-radio-group>
            </div>
            <div class="row">
              <div
                class="col-sm-6"
                [ngClass]="groupapp ? '' : 'hide'"
                id="ptagsgroups"
              >
                <div class="fv-row fv-plugins-icon-container">
                  <input
                    class="mb-5 btn_psecondary tagsgroups"
                    value="{{ groups }}"
                    name="tagsgroups"
                  />
                </div>
              </div>
              <div
                class="col-sm-6"
                id="ptagsuser"
                [ngClass]="userapp ? '' : 'hide'"
              >
                <div class="fv-row fv-plugins-icon-container">
                  <input
                    class="mb-5 btn_psecondary tagsuser"
                    value="{{ users }}"
                    name="tagsuser"
                  />
                </div>
              </div>
              <div class="col-sm-6" *ngIf="isothers">
                <div class="fv-row fv-plugins-icon-container">
                  <select
                    class="form-select btn_psecondary slcothers"
                    tabindex="-1"
                    (change)="slcothers($event)"
                  >
                    <option
                      value="formsubmitter"
                      [selected]="isotherslist == 'formsubmitter'"
                    >
                      Form Submitter
                    </option>
                    <option
                      *ngFor="let control of formControls"
                      [value]="control.id"
                      [attr.data-id]="control.id"
                      [selected]="isotherslist == control.id"
                    >
                      {{ control.fieldName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="beginid == 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('save')"
    >Save</a
  >
  <a
    *ngIf="beginid != 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('update')"
    >Update</a
  >
</mat-dialog-actions>
