/* eslint-disable */
"use strict";

import { QFUtil } from "./../../components/util.js";
import QFLayoutBrand from "./brand.js";
import QFMenu from "./../../components/menu.js";
import QFLayoutAside from "./aside.js";

var QFLayoutAsideMenu = function() {
    // Private properties
    var _body;
    var _element;
    var _menuObject;

	// Initialize
	var _init = function() {
		var menuDesktopMode = (QFUtil.attr(_element, 'data-menu-dropdown') === '1' ? 'dropdown' : 'accordion');
        var scroll;

		if (QFUtil.attr(_element, 'data-menu-scroll') === '1') {
			scroll = {
				rememberPosition: true, // remember position on page reload
				height: function() { // calculate available scrollable area height
					var height = parseInt(QFUtil.getViewPort().height);

					if (QFUtil.isBreakpointUp('lg')) {
						height = height - QFLayoutBrand.getHeight();
					}

					height = height - (parseInt(QFUtil.css(_element, 'marginBottom')) + parseInt(QFUtil.css(_element, 'marginTop')));

					return height;
				}
			};
		}

		_menuObject = new QFMenu(_element, {
			// Vertical scroll
			scroll: scroll,

			// Submenu setup
			submenu: {
				desktop: menuDesktopMode,
				tablet: 'accordion', // menu set to accordion in tablet mode
				mobile: 'accordion' // menu set to accordion in mobile mode
			},

			// Accordion setup
			accordion: {
				expandAll: false // allow having multiple expanded accordions in the menu
			}
		});
	}

    var _initHover = function() {
        // Handle Minimized Aside Hover
		if (QFUtil.hasClass(_body, 'aside-fixed') && QFUtil.hasClass(_body, 'aside-minimize-hoverable')) {
			var insideTm;
			var outsideTm;

            // Handle Aside Hover Mode
			QFUtil.addEvent(_element, 'mouseenter', function(e) {
				e.preventDefault();

				if (QFUtil.isBreakpointUp('lg') === false) {
					return;
				}

				if (outsideTm) {
					clearTimeout(outsideTm);
					outsideTm = null;
				}

                if (insideTm) {
					clearTimeout(insideTm);
					insideTm = null;
				}

				insideTm = setTimeout(function() {
					if (QFUtil.hasClass(_body, 'aside-minimize') && QFUtil.isBreakpointUp('lg')) {
						// Hover class
						QFUtil.addClass(_body, 'aside-minimize-hover');

						QFLayoutAsideMenu.getMenu().scrollUpdate();
						QFLayoutAsideMenu.getMenu().scrollTop();
					}
				}, 50);
			});

			QFUtil.addEvent(QFLayoutAside.getElement(), 'mouseleave', function(e) {
				e.preventDefault();

				if (QFUtil.isBreakpointUp('lg') === false) {
					return;
				}

				if (insideTm) {
					clearTimeout(insideTm);
					insideTm = null;
				}

                if (outsideTm) {
					clearTimeout(outsideTm);
					outsideTm = null;
				}

				outsideTm = setTimeout(function() {
				    if (QFUtil.hasClass(_body, 'aside-minimize-hover') && QFUtil.isBreakpointUp('lg')) {
					    QFUtil.removeClass(_body, 'aside-minimize-hover');

						// Hover class
                        QFLayoutAsideMenu.getMenu().scrollUpdate();
						QFLayoutAsideMenu.getMenu().scrollTop();
					}
				}, 100);
			});
		}
	}

    // Public methods
	return {
		init: function(id) {
            _body = QFUtil.getBody();
            _element = QFUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize menu
            _init();
            _initHover();
		},

		getElement: function() {
			return _element;
		},

        getMenu: function() {
			return _menuObject;
		},

        pauseDropdownHover: function(time) {
			if (_menuObject) {
				_menuObject.pauseDropdownHover(time);
			}
		},

		closeMobileOffcanvas: function() {
			if (_menuObject && QFUtil.isMobileDevice()) {
				_menuObject.hide();
			}
		}
	};
}();

// Webpack support
if (typeof module !== 'undefined') {
	// module.exports = QFLayoutAsideMenu;
}

export default QFLayoutAsideMenu;