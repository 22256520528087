import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-newworkflow",
  templateUrl: "./newworkflow.component.html",
  styleUrls: ["./newworkflow.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewworkflowComponent implements OnInit {
  addWorkflowform: FormGroup;
  description: string;
  form_id: any;
  workflowid: any;
  workflowname: string;
  workflowstage: string;
  formname: string;
  panelOpenState: boolean = true;
  dataToImport: any;
  wlength: number;
  restartWorkflow: boolean = false;

  constructor(
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewworkflowComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      description,
      workflowid,
      form_id,
      wlength,
      workflowname,
      workflowstage,
      formname,
      restartWorkflow,
    }
  ) {
    this.description = description;
    this.form_id = form_id;
    this.workflowid = workflowid;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.wlength = wlength;
    this.formname = formname;
    this.restartWorkflow = restartWorkflow;

    this.addWorkflowform = fb.group({
      description: [description, Validators.required],
      formid: [form_id],
      workflowid: [workflowid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      wlength: [wlength],
      formname: [formname],
      restartWorkflow: [restartWorkflow],
    });
  }

  ngOnInit(): void {
    this.dataToImport = {
      drawflow: {
        Home: {
          data: {
            "1": {
              id: 1,
              name: "endflow",
              data: {},
              class: "endflow",
              html: '\n          <div class="cardflow">\n            <div class="title-box"><div class="tbold"><span class="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" fill="#fff"/></svg> End Flow</span></div><div class="title">End Flow</div></div>\n    <table class="table table-row-bordered mb-0"><tr><td class="ps-3 p-qsm-1 bg_cond">End</td></tr></table>       </div>\n          ',
              typenode: false,
              inputs: {
                input_1: { connections: [{ node: "7", input: "output_1" }] },
              },
              outputs: {},
              pos_x: 850,
              pos_y: 350,
            },

            "7": {
              id: 7,
              name: "beginform",
              data: {},
              class: "beginform",
              html: '\n        <div class="cardflow">\n<div class="title-box"><div class="tbold"><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" class="wfItem-icon"><path d="M10.813 3.75h3.006L10.344.274v3.007c0 .259.21.469.469.469z" fill="#23283A"></path><path d="M3.313 16h9.374c.776 0 1.407-.63 1.407-1.406V4.688h-3.281c-.776 0-1.407-.631-1.407-1.407V0H3.312c-.775 0-1.406.63-1.406 1.406v13.188c0 .775.631 1.406 1.407 1.406zm2.812-8.469h4.688a.468.468 0 110 .938H6.125a.468.468 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zM4.25 7.531a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938z" fill="#23283A"></path></svg> Begin</div><div class="title">Begin Flow</div></div>\n  <table class="table table-row-bordered mb-0"><tr><td class="p-qsm-1 ps-3 bg_cond">Always</td></tr></table>       </div>\n        ',
              typenode: false,
              inputs: {},
              outputs: {
                output_1: {
                  connections: [{ node: "1", output: "input_1" }],
                },
              },
              pos_x: 100,
              pos_y: 87,
            },
          },
        },
      },
    };
  }

  saveworkflowdata(qfstate) {
    this.ngxService.start();
    if (qfstate === "save") {
      const data = {
        form_id: this.addWorkflowform.value.formid,
        form_name: this.addWorkflowform.value.workflowname,
        workflowdata: this.dataToImport,
        stage: this.addWorkflowform.value.workflowstage,
        status: false,
        checkedout: true,
        user_id: this.api.getUserId(),
        restartWorkflow: this.addWorkflowform.value.restartWorkflow,
      };

      this.api.saveWorkFlow(data).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res && Number(res.responseCode) === 201) {
            let workflow__id = res.recid;
            this.toastr.success("Workflow saved successfully", "Success");
            //if (this.wlength === 0) {
            // this.updateQForms();`
            //}
            this.dialogRef.close({ cancel: false, fetch: true, res: res });
            this.router.navigateByUrl(
              "workstage/" + this.form_id + "/" + workflow__id + "/checkout"
            );
          } else if (res && res.responseCode === 403 && res.exists === true) {
            this.toastr.warning("Workflow Name already exists!", "Warning");
          } else {
            this.toastr.error("Saving Workflow failed! ", "Error!");
          }
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
    }
    if (qfstate === "update") {
      const data = {
        workflowid: this.addWorkflowform.value.workflowid,
        form_id: this.addWorkflowform.value.formid,
        form_name: this.addWorkflowform.value.workflowname,
        stage: this.addWorkflowform.value.workflowstage,
        restartWorkflow: this.addWorkflowform.value.restartWorkflow,
      };
      this.api.updateWorkflowdata(data, data.workflowid).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res && Number(res.responseCode) === 201) {
            this.toastr.success("Workflow Updated successfully", "Success");
            this.dialogRef.close({ cancel: false, fetch: true });
          } else if (res && res.responseCode === 403 && res.exists === true) {
            this.toastr.warning("Workflow Name already exists!", "Warning");
          } else {
            this.toastr.error("Updating Workflow failed! ", "Error!");
          }
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
    }

    this.ngxService.stop();
  }

  close() {
    this.dialogRef.close();
  }
}
