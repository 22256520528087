export const environment = {
  production: false,
  appVersion: "v717demo1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: true,
  //apiUrl: "http://workflowbackend/api",
  //qformUrl: "http://backend",

  baseUrl: "https://maheflow.qforms.co",
  apiUrl: "https://maheflowapi.qforms.co/api",
  qformUrl: "https://maheapi.qforms.co",
  qformFrontEndUrl: "https://mahe.qforms.co",
  INTEGRATION_HUB_FRONTEND_URL: "https://maheihub.qforms.co",
  INTEGRATION_HUB_BACKEND_URL: "http://maheihubapi.qforms.co",

  // baseUrl: "http://localhost:4201",
  // apiUrl: "http://localhost/api",
  // //apiUrl: "https://stagingflowapi.qforms.co/api",
  // qformFrontEndUrl: "http://localhost:4206",
  // qformUrl: "http://localhost:83",
  // //qformUrl: "https://stagingapi.qforms.co",
  // INTEGRATION_HUB_FRONTEND_URL: "https://localhost:4200",
  // INTEGRATION_HUB_BACKEND_URL: "http://localhost:9000",
  // //INTEGRATION_HUB_BACKEND_URL: "https://stagingihubapi.qforms.co",

  // baseUrl: "http://awsworkflow.qforms.co",
  // apiUrl: "http://ec2-3-110-56-120.ap-south-1.compute.amazonaws.com:8122/api",
  // qformUrl: "http://ec2-3-109-58-248.ap-south-1.compute.amazonaws.com:8012",
  // qformFrontEndUrl: "http://aws.qforms.co",
  // INTEGRATION_HUB_FRONTEND_URL: "http://awsihub.qforms.co",
  // INTEGRATION_HUB_BACKEND_URL:
  //   "http://ec2-3-110-108-230.ap-south-1.compute.amazonaws.com:9000",

  servicenow_instanceid: "dev114543",
  servicenow_tablename: "u_mudasir_table_label",
};
export class Config {
  static DATE_FMT = "dd-MM-yyyy";
  static DATE_TIME_FMT = `${Config.DATE_FMT} hh:mma`;
  static secretKey = "drQ8pzjLMqEKZyAhyLqXlxZdyQTsEoiq";
}
