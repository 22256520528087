<!--begin::Main-->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div class="d-flex flex-column flex-root dashboardlist">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <ng-container>
        <!--begin::STATIC Aside-->
        <div #ktAside id="kt_aside"
          class="aside aside-light aside-hoverable aside-left d-flex flex-column flex-row-auto"
          [ngClass]="asideCSSClasses">
          <!--begin::Brand-->
          <div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
            <!--begin::Logo-->
            <a routerLink="/dashboardlist/{{ qformid }}" class="brand-logo">
              <img alt="Logo" [attr.src]="headerLogo" class="h-55px logo" />
            </a>
            <!--end::Logo-->

            <ng-container>
              <button class="brand-toggle btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_aside_toggle">
                <span [inlineSVG]="
                    './assets/media/svg/icons/Navigation/Angle-double-left.svg'
                  " cacheSVG="true" class="svg-icon svg-icon-xl"></span>
              </button>
            </ng-container>
          </div>
          <!--end::Brand-->

          <!--begin::Aside Menu-->
          <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
            <ng-container *ngIf="disableAsideSelfDisplay">
              <!--begin::Header Logo-->
              <a routerLink="/dashboardlist/{{ qformid }}">
                <img alt="Logo" [attr.src]="headerLogo" />
              </a>
              <!--end::Header Logo-->
            </ng-container>
            <!--begin::Menu Container-->
            <div id="kt_aside_menu" class="aside-menu" [ngClass]="asideMenuCSSClasses" data-menu-vertical="1"
              [attr.data-menu-scroll]="asideMenuScroll" [attr.data-menu-dropdown]="asideMenuDropdown"
              data-menu-dropdown-timeout="500">
              <div class="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
                data-kt-scroll-offset="0">
                <div
                  class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                  id="kt_aside_menu" data-kt-menu="true">
                  <ul class="menu-nav" [ngClass]="ulCSSClasses">
                    <!-- begin::1 Level -->
                    <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
                      <a href="javascript:void(0);" class="drag-drawflow menu-link">
                        <span class="menu-title fs-4 align-middle menu-text">Workflow List</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::STATIC Aside-->
      </ng-container>
      <!-- end:: Aside Left -->

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!-- begin:: Header -->
        <div #ktHeader id="kt_header" class="header" [ngClass]="headerCSSClasses">
          <!--begin::Container-->
          <div [ngClass]="headerContainerCSSClasses" class="d-flex align-items-stretch justify-content-center">
            <div *ngIf="!qformsLoaded ">
              <ng-container *ngIf="loader$ | async as _loader ">
                <ngb-progressbar class="loading-bar" height="3px" [value]="_loader"></ngb-progressbar>
              </ng-container>
            </div>
            <ng-container *ngIf="headerMenuSelfDisplay">
              <!--begin::Header Menu Wrapper-->
              <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                <ng-container *ngIf="!asideSelfDisplay">
                  <!--begin::Header Logo-->
                  <div class="header-logo">
                    <a routerLink="/">
                      <img alt="Logo" [attr.src]="headerLogo" />
                    </a>
                  </div>
                  <!--end::Header Logo-->
                </ng-container>
              </div>
              <!--end::Header Menu Wrapper-->
            </ng-container>

            <ng-container *ngIf="!headerMenuSelfDisplay">
              <div></div>
            </ng-container>

            <div class="text-center">
              <h1>{{ formname }}</h1>
            </div>
          </div>
          <!--end::Container-->
        </div>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
          <ng-container *ngIf="!contentExtended ">
            <div class="row">
              <!--begin::Entry-->
              <div class="d-flex flex-column-fluid px-10">
                <!--begin::Container-->
                <div class="m-5">
                  <!--begin::Row-->
                  <div class="row">
                    <!--begin::Col-->
                    <div class="col-lg-12 col-xxl-12">
                      <!--begin::Tables Widget 9-->
                      <div class="card card-xl-stretch mb-5 mb-xl-8">
                        <!--begin::Header-->
                        <div class="card-header border-0 pt-5">
                          <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1"></span>
                          </h3>
                          <div class="card-toolbar">
                            <a href="javascript:void(0);" class="btn btn-sm btn-light-primary fw-bolder"
                              (click)="openDialog()">
                              <span class="svg-icon svg-icon-3">
                                <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"
                                  class="wfItem-icon">
                                  <path d="M10.813 3.75h3.006L10.344.274v3.007c0 .259.21.469.469.469z" fill="#23283A">
                                  </path>
                                  <path
                                    d="M3.313 16h9.374c.776 0 1.407-.63 1.407-1.406V4.688h-3.281c-.776 0-1.407-.631-1.407-1.407V0H3.312c-.775 0-1.406.63-1.406 1.406v13.188c0 .775.631 1.406 1.407 1.406zm2.812-8.469h4.688a.468.468 0 110 .938H6.125a.468.468 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zm0 1.875h4.688a.468.468 0 110 .938H6.125a.469.469 0 110-.938zM4.25 7.531a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938zm0 1.875a.469.469 0 110 .938.469.469 0 010-.938z"
                                    fill="#23283A"></path>
                                </svg>
                              </span>
                              <!--end::Svg Icon-->New Workflow
                            </a>
                          </div>
                        </div>
                        <!--end::Header-->

                        <!--begin::Body-->
                        <div class="card-body py-3">
                          <div class="row">
                            <ul class="nav nav-dark nav-bold nav-tabs" role="tablist">
                              <li class="nav-item">
                                <a (click)="setActiveTab(1)" class="nav-link cursor-pointer"
                                  [ngClass]="getActiveTabCSSClass(1)" role="tab">
                                  <h3>Published</h3>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a (click)="setActiveTab(2)" class="nav-link cursor-pointer"
                                  [ngClass]="getActiveTabCSSClass(2)" role="tab">
                                  <h3>Checked Out</h3>
                                </a>
                              </li>
                            </ul>

                            <div class="tab-content pt-3">
                              <!-- begin::Header -->
                              <div class="tab-pane" [ngClass]="getActiveTabCSSClass(1)">
                                <!--begin::Table container-->
                                <!--begin::Table-->
                                <table id="tblworkflow"
                                  class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <!--begin::Table head-->
                                  <thead>
                                    <tr class="fw-bolder">
                                      <th class="min-w-150px">Workflow Name</th>
                                      <th class="min-w-70px">Version</th>
                                      <th class="min-w-150px">Form Name</th>
                                      <th class="min-w-100px">Publish by</th>
                                      <th class="min-w-120px">Publish On</th>
                                      <th class="min-w-80px">Published</th>
                                      <th class="min-w-100px text-end">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <!--end::Table head-->
                                  <!--begin::Table body-->
                                  <tbody>
                                    <tr *ngFor="
                                        let item of publisheddata;
                                        let i = index
                                      ">
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div class="d-flex justify-content-start flex-column mt-2">
                                            <a href="#/workstage/{{
                                                item.form_id
                                              }}/{{ item._id }}/publish" class="text-dark text-hover-primary fs-6">{{
                                              item.form_name }}</a>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-hover-primary text-center d-block fs-6 mt-2">
                                          v{{ item.version }}
                                        </div>
                                      </td>
                                      <td>
                                        <div class="d-block mt-2">
                                          {{ formname }}
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-hover-primary d-block fs-6 mt-2">
                                          {{ username }}
                                        </div>
                                      </td>
                                      <td class="text-end">
                                        <div class="d-flex flex-column w-100 me-2 mt-2">
                                          <div class="d-flex flex-stack mb-2">
                                            <span class="me-2">{{
                                              item.updated_at
                                              | date : "MMM dd, yyyy hh:mm a"
                                              }}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-hover-primary text-center d-block fs-6 mt-2">
                                          {{ item.status }}
                                        </div>
                                      </td>
                                      <td class="text-end">
                                        <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline"
                                          title="Quick actions">
                                          <a ngbDropdownToggle
                                            class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ki ki-bold-more-hor"></i>
                                          </a>
                                          <div ngbDropdownMenu
                                            class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <ul class="navi navi-hover py-5">
                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    CheckedOutWorkflow(
                                                      item.workflow_id,
                                                      item.form_id
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-telegram-logo"></i></span><span
                                                    class="navi-text">Check Out</span></a>
                                              </li>

                                              <li class="navi-item" [ngClass]="
                                                  getHistoryStatus(
                                                    item.form_name
                                                  )
                                                ">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    openHistory(
                                                      item._id,
                                                      item.form_id,
                                                      item.form_name,
                                                      item.workflow_id
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-schedule"></i></span><span
                                                    class="navi-text">History</span></a>
                                              </li>

                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    copyworkflow(
                                                      item._id,
                                                      item.form_id,
                                                      'published'
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-copy"></i></span><span
                                                    class="navi-text">Copy</span></a>
                                              </li>
                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    openConfirmFormDelete(
                                                      item._id,
                                                      item.workflow_id,
                                                      item.form_name,
                                                      'published'
                                                    )
                                                  "><span class="navi-icon"><i class="flaticon2-trash"></i></span><span
                                                    class="navi-text">Delete</span></a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--end::Table container-->
                              </div>
                              <!-- end::Header -->

                              <!-- begin::Subheader -->
                              <div class="tab-pane" [ngClass]="getActiveTabCSSClass(2)">
                                <table id="tblCheckedout"
                                  class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <!--begin::Table head-->
                                  <thead>
                                    <tr class="fw-bolder">
                                      <th class="min-w-150px">Workflow Name</th>
                                      <th class="min-w-150px">Form Name</th>
                                      <th class="min-w-140px">Check Out by</th>
                                      <th class="min-w-120px">Check Out On</th>
                                      <th class="min-w-120px">Published</th>
                                      <th class="min-w-100px text-end">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <!--end::Table head-->
                                  <!--begin::Table body-->
                                  <tbody>
                                    <tr *ngFor="
                                        let item of checkedoutdata;
                                        let i = index
                                      ">
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div class="d-flex justify-content-start flex-column mt-2">
                                            <a href="#/workstage/{{
                                                item.form_id
                                              }}/{{ item._id }}/checkout" class="text-dark text-hover-primary fs-6">{{
                                              item.form_name }}</a>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="d-block mt-2">
                                          {{ formname }}
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-hover-primary d-block fs-6 mt-2">
                                          {{ username }}
                                        </div>
                                      </td>
                                      <td class="text-end">
                                        <div class="d-flex flex-column w-100 me-2 mt-2">
                                          <div class="d-flex flex-stack mb-2">
                                            <span class="me-2">{{
                                              item.updated_at
                                              | date : "MMM dd, yyyy hh:mm a"
                                              }}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="text-hover-primary d-block fs-6 mt-2">
                                          false
                                        </div>
                                      </td>
                                      <td class="text-end">
                                        <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline"
                                          title="Quick actions">
                                          <a ngbDropdownToggle
                                            class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ki ki-bold-more-hor"></i>
                                          </a>
                                          <div ngbDropdownMenu
                                            class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <ul class="navi navi-hover py-5">
                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    publishworkflow(
                                                      item._id,
                                                      item.form_id,
                                                      item.form_name
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-telegram-logo"></i></span><span
                                                    class="navi-text">Publish</span></a>
                                              </li>
                                              <li class="navi-item" id="kt_quick_panel_toggle">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    changeStage(
                                                      'checkout',
                                                      item._id
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-setup"></i></span><span
                                                    class="navi-text">Stages</span></a>
                                              </li>
                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    copyworkflow(
                                                      item._id,
                                                      item.form_id,
                                                      'checkedout'
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="navi-icon flaticon2-copy"></i></span><span
                                                    class="navi-text">Copy</span></a>
                                              </li>
                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    editWorkflow(
                                                      item._id,
                                                      item.form_id
                                                    )
                                                  "><span class="navi-icon"><i
                                                      class="flaticon2-list-3"></i></span><span
                                                    class="navi-text">Edit</span></a>
                                              </li>

                                              <li class="navi-item">
                                                <a class="navi-link cursor-pointer" (click)="
                                                    openConfirmFormDelete(
                                                      item._id,
                                                      item._id,
                                                      item.form_name,
                                                      'checkedout'
                                                    )
                                                  "><span class="navi-icon"><i class="flaticon2-trash"></i></span><span
                                                    class="navi-text">Delete</span></a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- end::Subheader -->
                            </div>
                          </div>
                        </div>
                        <!--begin::Body-->
                      </div>
                      <!--end::Tables Widget 9-->
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                  <!--end::Container-->
                </div>
              </div>
              <!--end::Entry-->
            </div>
          </ng-container>
          <!-- end:: Content Body -->
        </div>
        <!-- end:: Content -->
      </div>
      <!--end::Wrapper-->
    </div>
    <!-- end:: Body -->
    <!--end::Page-->
    <!-- begin :: STAGES POPUP -->
    <ng-container *ngIf="extrasQuickPanelDisplay">
      <!--begin::Quick Panel-->
      <div id="kt_quick_panel" class="offcanvas pt-5 pb-10"
        [ngClass]="extrasQuickPanelOffcanvasDirectionCSSClassStages">
        <!--begin::Header-->
        <div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            role="tablist">
            <li class="nav-item">
              <a class="nav-link cursor-pointer" data-toggle="tab">Workflow Stages/States</a>
            </li>
          </ul>
          <div class="offcanvas-close mt-n1 pr-5">
            <a (click)="closeStages()" class="btn btn-xs btn-icon btn-light btn-hover-primary mt-3"
              id="kt_quick_panel_close">
              <i class="ki ki-close close-xs text-muted"></i>
            </a>
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Content-->
        <div class="offcanvas-content px-10">
          <div class="tab-content">
            <!--begin::Tabpane-->
            <div class="tab-pane pt-3 pr-5 mr-n5 show stageOverflow" id="kt_quick_panel_logs" role="tabpanel">
              <!--begin::Section-->
              <div class="mb-15">
                <form [formGroup]="stagesform" novalidate>
                  <div class="row mb-10">
                    <div class="col-md-3">
                      <button class="btn btn-secondary pb-1 pt-1 border-bolder" aria-controls="newStages"
                        (click)="newStagesCreated(false)">
                        New Stages
                      </button>
                    </div>
                  </div>
                  <div id="newStages" class="mb-10" [ngbCollapse]="
                      workflowStages.length <= 0 ? false : newStages
                    ">
                    <div class="mb-5">
                      <div class="form-group row mb-2">
                        <div class="col-lg-12">
                          <input type="text" formControlName="stageName" (keyup)="setWorkflowStage($event)"
                            class="form-control text-muted" placeholder="Stage Name" />
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <div class="col-lg-12">
                          <input type="text" formControlName="stageValue" class="form-control text-muted"
                            placeholder="Stage Value" disabled="disabled" />
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <div class="col-lg-12 text-right">
                          <button type="button" class="btn btn-secondary pb-1 pt-1 border-bolder"
                            (click)="saveStages()">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="workflowStages.length > 0" class="row border-bottom mb-5">
                    <div class="col-md-6 font-weight-bolder">Name</div>
                    <div class="col-md-3 font-weight-bolder text-right">
                      Show
                    </div>
                    <div class="col-md-3 font-weight-bolder text-right">
                      Actions
                    </div>
                  </div>

                  <!--begin: Dynamic Item-->
                  <div formArrayName="workflowStates">
                    <div *ngFor="
                        let stagesItem of workflowStages;
                        let stageIndex = index
                      " [formGroupName]="stageIndex">
                      <div class="d-flex align-items-center flex-wrap mb-5">
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                          <div id="divStgname{{ stageIndex }}" contenteditable="true"
                            (keyup)="getSelectionCursor($event, stageIndex)"
                            (keydown.backspace)="jumpCursor(stageIndex)"
                            class="text-dark-75 text-hover-primary font-size-lg mb-1">
                            <div>{{ stagesItem.stageName }}</div>
                          </div>
                        </div>
                        <div class="col-3">
                          <span class="switch switch-sm">
                            <label>
                              <input type="checkbox" (change)="onShowChanged($event, stageIndex)" [checked]="
                                  stagesItem.isActive == true ? true : false
                                " name="select" />
                              <span></span>
                            </label>
                          </span>
                        </div>
                        <span class="py-1 my-lg-0 my-2 pr-5" (click)="deleteState(stageIndex)"><a
                            class="navi-link cursor-pointer"><span class="navi-icon"><i
                                class="flaticon2-trash f12"></i></span></a></span>
                        <span *ngIf="stagesItem.isExpand" class="py-1 my-lg-0 my-2" id="item{{ stageIndex }}"><a
                            class="navi-link cursor-pointer" (click)="stageExpanded(false, stageIndex)"
                            [attr.aria-controls]="'collapseStages' + stageIndex" aria-controls="collapseStages"><span
                              class="navi-icon"><i class="flaticon2-down f12"></i></span></a></span>
                        <span *ngIf="!stagesItem.isExpand" class="py-1 my-lg-0 my-2" id="item{{ stageIndex }}"><a
                            class="navi-link cursor-pointer" (click)="stageCollapsed(true, stageIndex)"><span
                              class="navi-icon"><i class="flaticon2-up f12"></i></span></a></span>
                      </div>
                      <div *ngIf="stageListIndex == stageIndex">
                        <div id="collapseStages{{ stageIndex }}" [ngbCollapse]="stagesCollapsed">
                          <div class="mb-5">
                            <div class="form-group mb-8">
                              <div class="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                                Please rename the states to your choosing. These
                                states will be displayed to the end user who is
                                viewing the different stages of the flow.
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">Pending</label>
                              <div class="col-lg-9">
                                <input type="text" id="pending{{ stageIndex }}" class="form-control text-muted"
                                  formControlName="pending" />
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">In Progress</label>
                              <div class="col-lg-9 text-muted">
                                <input type="text" id="inporgress{{ stageIndex }}" formControlName="inprogress"
                                  class="form-control text-muted" />
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">Approved</label>
                              <div class="col-lg-9">
                                <input type="text" id="approved{{ stageIndex }}" formControlName="approved"
                                  class="form-control text-muted" />
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">Rejected</label>
                              <div class="col-lg-9">
                                <input type="text" id="rejected{{ stageIndex }}" formControlName="rejected"
                                  class="form-control text-muted" />
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">Completed</label>
                              <div class="col-lg-9">
                                <input type="text" id="rejected{{ stageIndex }}" formControlName="completed"
                                  class="form-control text-muted" />
                              </div>
                            </div>
                            <div class="form-group row mb-2">
                              <label class="col-lg-3 col-form-label text-right text-muted">Request More Info</label>
                              <div class="col-lg-9">
                                <input type="text" id="rejected{{ stageIndex }}" formControlName="requestedmoreinfo"
                                  class="form-control text-muted" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group text-right" *ngIf="workflowStages.length > 0">
                    <button type="button" (click)="closeStages()" class="btn btn-default pb-1 pt-1 border-bolder mr-5">
                      Close
                    </button>
                    <button type="button" (click)="saveStates()" class="btn btn-primary pb-1 pt-1 border-bolder">
                      Save
                    </button>
                  </div>
                </form>
                <!--end: Item-->
              </div>
              <!--end::Section-->
            </div>
            <!--end::Tabpane-->
          </div>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Quick Panel-->
    </ng-container>
    <!-- end:: STAGES POPUP-->

    <!-- begin :: WORKFLOW PUBLISH POPUP -->
    <!--begin::Quick Panel-->
    <div id="kt_approve_workflow" class="offcanvas pt-5 pb-10" [ngClass]="extrasQuickPanelOffcanvasDirectionCSSClass">
      <!--begin::Header-->
      <div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist">
          <li class="nav-item">
            <h3 class="nav-link cursor-pointer" data-toggle="tab">
              Approve Workflow
            </h3>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a (click)="closeApproveStages()" class="btn btn-xs btn-icon btn-light btn-hover-primary mt-3"
            id="kt_quick_panel_close">
            <i class="ki ki-close close-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <!--begin::Tabpane-->
          <div class="tab-pane pt-3 pr-5 mr-n5 show stageOverflow" id="kt_quick_panel_logs" role="tabpanel">
            <!--begin::Section-->
            <div class="mb-15">
              <div id="approveWorkflow" class="mb-10 mt-30">
                <div class="mb-5">
                  <div class="form-group row">
                    <div class="col-lg-12 mb-2">
                      <label class="form-check form-switch form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="isApproved"
                          (change)="changeApprovalType($event)" />
                        <div class="pl-15">Send for Approval</div>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row mb-2" *ngIf="isApproved">
                    <div class="col-lg-12">
                      <label>Select approver</label>
                      <select class="form-select btn_psecondary slcApproved" tabindex="-1" [(ngModel)]="approverId"
                        (change)="changeApprover($event)">
                        <option *ngFor="let control of userList; let i = index" [value]="control.id"
                          [attr.data-id]="control.id" [selected]="
                            approverId == control.id
                              ? true
                              : approverId === 0
                              ? true
                              : false
                          ">
                          {{ control.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <div class="col-lg-12">
                      <label>Description of change</label>
                      <textarea class="form-control approver-desc" [(ngModel)]="appDesc"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-2" *ngIf="isApproved">
                    <div class="col-lg-12">
                      <label>Due Date</label>
                      <div class="input-group date apdate" data-provide="datepicker">
                        <input type="text" class="form-control" id="txtdueDate" [(ngModel)]="dueDate"
                          placeholder="mm/dd/yyyy" autocomplete="off" />
                        <div class="input-group-append">
                          <button class="btn btn_psecondary calendar" type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-2">
                    <div class="col-lg-12 text-right">
                      <button type="button" class="btn btn-secondary pb-1 pt-1 border-bolder mr-5"
                        (click)="approveWorkflow()">
                        Send
                      </button>
                      <button type="button" class="btn btn-secondary pb-1 pt-1 border-bolder" (click)="closeStages()">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Item-->
            </div>
            <!--end::Section-->
          </div>
          <!--end::Tabpane-->
        </div>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Quick Panel-->
    <!-- end:: STAGES POPUP-->
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class="d-flex flex-column flex-root">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<!--end::Main-->