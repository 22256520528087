import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ActivatedRoute, Router } from "@angular/router";

export interface DialogData {
  header: string;
  content: string;
  postivebtnLabel: string;
  negativeBtnLabel: string;
  id: string;
  name: string;
}

@Component({
  selector: "app-unsaved",
  templateUrl: "./unsaved.component.html",
  styleUrls: ["./unsaved.component.scss"],
})
export class UnsavedComponent implements OnInit {
  responseCode = 0;
  active = true;
  formid: any;

  constructor(
    public dialogRef: MatDialogRef<UnsavedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: WorkFlowService,
    private route: ActivatedRoute,
    public router: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.formid = this.apiService.getFormId();
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close({ cancel: true, data: {} });
  }

  onSubmit() {
    this.apiService.setUnsaved("false");
    this.dialogRef.close({ cancel: false, data: {} });
  }
}
