<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <input hidden matInput formControlName="nodeid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        (click)="close()"
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div class="mb-md-0 fw-bold">
          <h4 class="text-gray-900 fw-bolder">
            Workflow Activity -
            {{ description }} for [ {{ formname }} ]
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Branch Name</mat-label>
            <input matInput formControlName="workflowname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Stage</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Branch Stage</mat-label>
            <input matInput formControlName="workflowstage" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="row mb-8">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title
                ><h2 class="accordion-header fw-bold acc_button">
                  Merge Options
                </h2></mat-panel-title
              >
            </mat-expansion-panel-header>

            <mat-radio-group>
              <div class="row">
                <div class="col-sm-12 mb-5 mt-3">
                  <mat-radio-button
                    value="all"
                    (change)="radioChange($event)"
                    [checked]="branchdata == 'all' ? true : false"
                    name="group"
                  >
                    <div class="text-left">
                      <label class="form-label fw-bold"
                        >Wait for all branches to be completed.</label
                      >
                    </div>
                    <div class="fs-7 fw-bold text-muted">
                      Wait until all branches are completed then move to the
                      next step.
                    </div>
                  </mat-radio-button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-5 mt-3">
                  <mat-radio-button
                    value="any"
                    (change)="radioChange($event)"
                    [checked]="branchdata == 'any' ? true : false"
                    name="group"
                  >
                    <div class="text-left">
                      <label class="form-label fw-bold"
                        >Wait for any of the branches to be completed.</label
                      >
                    </div>
                    <div class="fs-7 fw-bold text-muted">
                      Wait for any of the branches to complete then move to the
                      next step. Other remaining active branches will expire.
                    </div>
                  </mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="beginid == 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('mergebranches', 'save')"
    >Save</a
  >
  <a
    *ngIf="beginid != 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('mergebranches', 'update')"
    >Update</a
  >
</mat-dialog-actions>
