import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "src/environments/environment";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { MatRadioModule } from "@angular/material/radio";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { TagifyModule } from "ngx-tagify";

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { SplashScreenModule } from "./_qform/partials/layout/splash-screen/splash-screen.module";
import { ApprovalComponent } from "./modals/approval/approval.component";
import { BeginComponent } from "./modals/begin/begin.component";
import { AssignComponent } from "./modals/assign/assign.component";
import { SendemailComponent } from "./modals/sendemail/sendemail.component";
import { ConditionalbranchComponent } from "./modals/conditionalbranch/conditionalbranch.component";
import { RunscriptComponent } from "./modals/runscript/runscript.component";
import { ToastrModule } from "ngx-toastr";
import { DashboardlistComponent } from "./_qform/partials/dashboardlist/dashboardlist.component";
import { NewworkflowComponent } from "./modals/newworkflow/newworkflow.component";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { ConfirmdeleteComponent } from "./modals/confirmdelete/confirmdelete.component";
import { UnsavedComponent } from "./modals/unsaved/unsaved.component";
import { MergebranchesComponent } from "./modals/mergebranches/mergebranches.component";
import { SplitComponent } from "./modals/split/split.component";
import { EndflowComponent } from "./modals/endflow/endflow.component";
import { IfelseComponent } from "./modals/ifelse/ifelse.component";
import { ApprovalreportComponent } from "./modals/approvalreport/approvalreport.component";
import { CopyworkflowComponent } from "./modals/copyworkflow/copyworkflow.component";
import { WorkflowhistoryComponent } from "./modals/workflowhistory/workflowhistory.component";
import { ServicenowComponent } from "./_qform/partials/servicenow/servicenow.component";
import { SalesforceComponent } from "./_qform/partials/salesforce/salesforce.component";
import { ServiceNowModalComponent } from "./modals/service-now-modal/service-now-modal.component";
import { SalesforceModalComponent } from "./modals/salesforce-modal/salesforce-modal.component";
import { WorkflowComponent } from "./_qform/partials/workflow/workflow.component";
import { IntegrationhubComponent } from './modals/integrationhub/integrationhub.component';

@NgModule({
  declarations: [
    AppComponent,
    ApprovalComponent,
    BeginComponent,
    AssignComponent,
    SendemailComponent,
    ConditionalbranchComponent,
    RunscriptComponent,
    DashboardlistComponent,
    NewworkflowComponent,
    ConfirmdeleteComponent,
    UnsavedComponent,
    MergebranchesComponent,
    SplitComponent,
    EndflowComponent,
    IfelseComponent,
    ApprovalreportComponent,
    CopyworkflowComponent,
    WorkflowhistoryComponent,
    ServicenowComponent,
    SalesforceComponent,
    ServiceNowModalComponent,
    SalesforceModalComponent,
    WorkflowComponent,
    IntegrationhubComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CdkTableModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatDividerModule,
    MatRadioModule,
    NgxUiLoaderModule,

    InlineSVGModule.forRoot(),
    TagifyModule.forRoot(),
    NgbModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
          json: () => import("highlight.js/lib/languages/json"),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ApprovalComponent],
})
export class AppModule {}
