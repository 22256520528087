import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { WorkFlowService } from "src/app/services/work-flow.service";

export interface DialogData {
  header: string;
  content: string;
  postivebtnLabel: string;
  negativeBtnLabel: string;
  id: string;
  workflowid: string;
  name: string;
  mode: string;
}

@Component({
  selector: "app-confirmdelete",
  templateUrl: "./confirmdelete.component.html",
  styleUrls: ["./confirmdelete.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmdeleteComponent implements OnInit {
  responseCode = 0;
  active = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmdeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close({ cancel: true, data: {} });
  }
  onSubmit() {
    this.ngxService.start();
    this.apiService
      .deleteWorkflow(this.data.id, this.data.workflowid, this.data.mode)
      .subscribe(
        (res: any) => {
          if (res && res.responseCode === 200) {
            this.toastr.success("Workflow Deleted Successfully", "Success");
            this.dialogRef.close({ cancel: false, fetch: true });
          } else {
            this.toastr.error("Failed to Delete Workflow! ", "Error!");
          }
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
  }
}
