<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>&nbsp;</h2>
</div>

<mat-dialog-content [formGroup]="addWorkflowform">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border">
      <button
        title=""
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-3">
        <div class="mb-3 mb-md-0 fw-bold pt-2">
          <h4 class="text-gray-900 fw-bolder">
            {{ formname }} -
            {{ description }}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right pt-10">
        <div class="fs-6 fw-bold mt-5">Name</div>
      </div>
      <div class="col-xl-10 pt-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Workflow Name</mat-label>
            <input matInput formControlName="workflowname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-2 text-right pt-10"></div>
      <div class="col-xl-10 pt-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <div class="d-flex flex-stack mb-8 mt-8 col-md-11">
            <div class="me-5">
              <label class="fs-6 fw-bold"
                >Restart Workflow On Submission Edit</label
              >
              <div class="fs-7 fw-bold text-muted">
                The workflow will automatically start every time submitters edit
                their submissions.
              </div>
            </div>
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="restartWorkflow"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="workflowid == 0"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveworkflowdata('save')"
    >Save</a
  >
  <a
    *ngIf="workflowid != 0"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveworkflowdata('update')"
    >Update</a
  >
</mat-dialog-actions>
