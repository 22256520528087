import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WorkFlowService } from "src/app/services/work-flow.service";

export interface DialogData {
  header: string;
  content: string;
  postivebtnLabel: string;
  negativeBtnLabel: string;
  workflowid: string;
  workflowname: string;
  formid: string;
  mode: string;
}

@Component({
  selector: "app-copyworkflow",
  templateUrl: "./copyworkflow.component.html",
  styleUrls: ["./copyworkflow.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CopyworkflowComponent implements OnInit {
  responseCode = 0;
  active = true;
  form: FormGroup;
  formid: any;
  workflowid: any;
  workflowname: any;
  mode: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public dialogRef: MatDialogRef<CopyworkflowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.formid = data.formid;
    this.mode = data.mode;
    this.workflowid = data.workflowid;
    this.workflowname = data.workflowname;
    this.form = fb.group({
      formid: [data.formid],
      workflowid: [data.workflowid],
      workflowname: [data.workflowname],
    });
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close({ cancel: true, data: {} });
  }
  onSubmit() {
    const data = {
      formid: this.formid,
      mode: this.mode,
      workflowid: this.workflowid,
      formname: this.form.value.workflowname,
    };
    let validform = true;
    if (this.form.value.workflowname == "") {
      validform = false;
      this.toastr.error("Workflow Name cannot be empty! ", "Error!");
    }
    if (validform) {
      this.api.copyWorkFlow(data, this.mode).subscribe(
        (res: any) => {
          if (res && Number(res.responseCode) === 201) {
            this.toastr.success("Workflow saved successfully", "Success");
            this.dialogRef.close({ cancel: false, fetch: true });
          } else if (res && res.responseCode === 403 && res.exists === true) {
            this.toastr.warning("Workflow Name already exists!", "Warning");
          } else {
            this.toastr.error("Saving Workflow failed! ", "Error!");
          }
        },
        (err) => {
          this.ngxService.stop();
          console.log(err);
        }
      );
    }
  }
}
