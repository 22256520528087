<div mat-dialog-title style="padding-left: 5px">
  <div fxFlex fxLayout="row" class="row mt-5">
    <div fxFlex="80" style="padding-top: 2px" class="col-md-10">
      <h2 *ngIf="responseCode == 0" style="padding-left: 5px">
        {{ "Copy Workflow" }}
      </h2>
    </div>
    <div fxFlex="20" fxLayoutAlign="end center" class="col-md-2">
      <button mat-icon-button (click)="onCancelClick()">
        <i class="fa fa-times-circle" style="font-size: 20px"></i>
      </button>
    </div>
  </div>
</div>

<mat-dialog-content [formGroup]="form">
  <div class="row">
    <div class="col-md-12 mt-5">
      <input
        formControlName="workflowname"
        class="form-control"
        placeholder="Workflow Name"
      />
    </div>
    <div class="fv-plugins-message-container invalid-feedback"></div>
  </div>
</mat-dialog-content>

<div>
  <div fxLayout="column" fxFlex>
    <div fxLayout="row" class="text-center">
      <button
        *ngIf="responseCode == 0"
        type="submit"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
        class="me-5"
      >
        {{ data.postivebtnLabel }}
      </button>
      <button
        type="submit"
        mat-raised-button
        color="warn"
        (click)="onCancelClick()"
      >
        {{ data.negativeBtnLabel }}
      </button>
    </div>
  </div>
</div>
