import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import $ from "jquery";
import tinymce from "../../../assets/plugins/custom/tinymce/tinymce.bundle";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TagData, TagifyService, TagifySettings } from "ngx-tagify";

@Component({
  selector: "app-approvalreport",
  templateUrl: "./approvalreport.component.html",
  styleUrls: ["./approvalreport.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ApprovalreportComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  workflowname: any;
  workflowstage: any;
  nodeid: any;
  nodetype: any;
  emailsubject: string;
  emailcontent: string;
  sendername: string;
  replyto: string;
  erecipient: string;
  ispdfattach: boolean;
  ishiddenfields: boolean;
  senderemail: string;
  formControls: any = [];
  savedata: any;
  updatedata: any;
  panelOpenState: boolean = true;
  tabSelected = 0;
  tabtotal = 0;
  activateScript: boolean = true;
  formname: string;
  tags: any = [];
  tabsclick: boolean = false;
  ispdfattachtype: string;
  ispdfattachapi: boolean = true;
  ispublished: boolean = false;

  tagsettings: TagifySettings = {
    mode: "mix",
    duplicates: false,
  };

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private dialogRef: MatDialogRef<ApprovalreportComponent>,
    private tagifyService: TagifyService,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      workflowname,
      workflowstage,
      nodeid,
      nodetype,
      emailsubject,
      emailcontent,
      sendername,
      replyto,
      erecipient,
      ispdfattach,
      ispdfattachtype,
      ishiddenfields,
      senderemail,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.emailsubject = emailsubject;
    this.emailcontent = emailcontent;
    this.sendername = sendername;
    this.replyto = replyto;
    this.erecipient = erecipient;
    this.ispdfattach = ispdfattach;
    this.ispdfattachtype = ispdfattachtype;
    this.ishiddenfields = ishiddenfields;
    this.senderemail = senderemail;
    this.formname = localStorage.getItem("formname");
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      nodeid: [nodeid],
      emailsubject: [emailsubject, Validators.required],
      emailcontent: [emailcontent],
      sendername: [sendername],
      replyto: [replyto],
      erecipient: [erecipient],
      ispdfattach: [ispdfattach],
      ispdfattachtype: [ispdfattachtype],
      ishiddenfields: [ishiddenfields],
      senderemail: [senderemail],
      ispublished: [ispublished],
    });

    //if (this.workflowstage != "") {
    this.form.controls.workflowstage.disable();
    //}
  }

  ngOnInit(): void {
    this.getControls();
    if (this.description === "approvalreport") {
      this.description = "Send Approval Report";
    }
    localStorage.removeItem("emailsubject");
    localStorage.removeItem("emailcontent");
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      this.ispdfattachapi = res.form[0].isPdfOutput;
      for (let i = 0; i < res.controls.length; i++) {
        if (
          res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "rankingchoice" &&
          res.controls[i].controlType != "age" &&
          res.controls[i].controlType != "signature" &&
          res.controls[i].controlType != "barcode" &&
          res.controls[i].controlType != "expressionbox" &&
          res.controls[i].controlType != "recaptcha" &&
          res.controls[i].controlType != "pagebreak"
        ) {
          this.formControls.push(res.controls[i]);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (typeof tinymce !== "undefined") {
      tinymce.remove();
    }
    let _self = this;
    this.activateTagify(_self, this.tabsclick);
    this.activateEditor(_self);
  }

  tabChanged(tabEvent) {
    let _self = this;
    this.tabSelected = tabEvent.index;
    this.tabsclick = true;
    this.activatetags();
    if (this.tabSelected == 0) {
      this.tabtotal = 0;
      if (!localStorage.getItem("subjectlength")) {
        localStorage.setItem(
          "subjectlength",
          $(document).find("tagify").text()
        );
      }
    } else {
      this.tabtotal += 1;
    }

    if (this.tabtotal <= 1) {
      localStorage.setItem(
        "emailsubject",
        this.tagifyService.get("emailsubject").DOM.input.textContent
      );
      localStorage.setItem(
        "emailcontent",
        tinymce.get("qf_appr_cust_email").getContent({ format: "html" })
      );
      if (typeof tinymce !== "undefined") {
        tinymce.remove();
      }
      this.activateEditor(_self);
    }
    this.activateTagify(_self, this.tabSelected);
  }

  changePdf(event) {
    if (event.target.checked) {
      this.ispdfattach = true;
    } else {
      this.ispdfattach = false;
    }
  }

  activateEditor(_self) {
    tinymce.init({
      selector: "textarea#qf_appr_cust_email",
      height: 500,
      plugins: "preview, code",
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent  | preview code | mybutton",
      setup: function (editor) {
        var toggleState = false;
        editor.ui.registry.addMenuButton("mybutton", {
          text: "Form Fields",
          fetch: function (callback) {
            var items = [];
            _self.formControls.forEach((ctrl) => {
              items.push({
                type: "menuitem",
                text: ctrl.fieldName,
                onAction: function () {
                  editor.insertContent("&nbsp;{" + ctrl.fieldName + "}");
                },
              });
            });

            callback(items);
          },
        });
      },
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      init_instance_callback: function (editor) {
        editor.on("click", function (e) {
          localStorage.removeItem("emailcontent");
        });
      },
    });
  }

  activateTagify(_self, tabclick) {
    $(document)
      .off("click", ".frmfields")
      .on("click", ".frmfields", function () {
        _self.tags = [];
        var i = $(this).find(".frm-fields").text();
        i = i.toLowerCase();
        i = i.replace(/ /g, "");
        i = "[[" + i + "]]";
        i = _self.tags.push(i);
        _self.tagifyService.get("emailsubject").addTags(_self.tags);
        $(document).find(".dropdown-menu").hasClass("show")
          ? $(document).find(".dropdown-menu").removeClass("show")
          : "";

        /*code for set cursor in email subject*/
        var el = document.getElementsByClassName("tagify__input")[0];
        var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(el.childNodes[0], 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    $(document)
      .off("click", ".tagify__tag__removeBtn")
      .on("click", ".tagify__tag__removeBtn", function (e) {
        var velement = $(this).parent("tag").attr("value");
        _self.tags.forEach((element, index) => {
          if (element == velement) _self.tags.splice(index, 1);
        });
      });

    if (_self.beginid != 0 && tabclick === false) {
      $(document)
        .find(".tagify__tag-text")
        .each(function () {
          _self.tags.push($(this).text());
        });
    }
  }

  saveform(formstate) {
    let validform = true;
    let strsubject;
    if (this.tabSelected == 0) {
      strsubject = new String($(document).find("tagify").text());
      strsubject = strsubject.trim();
    } else {
      strsubject = new String(localStorage.getItem("subjectlength"));
    }

    if (strsubject.length <= 0) {
      this.toastr.error("Email Subject cannot be empty! ", "Error!");
      validform = false;
    }

    if (this.form.value.sendername == "") {
      this.toastr.error("Sender Name cannot be empty! ", "Error!");
      validform = false;
    }
    if (this.form.value.replyto == "") {
      this.toastr.error("Reply To Email cannot be empty! ", "Error!");
      validform = false;
    }
    if (this.form.value.erecipient == "") {
      this.toastr.error("Recipient Email cannot be empty! ", "Error!");
      validform = false;
    }

    if (this.form.value.senderemail == "") {
      this.toastr.error("Sender Email cannot be empty! ", "Error!");
      validform = false;
    }

    if (validform) {
      if (this.beginid === 0 && formstate == "save") {
        this.getsavefields("save");
      } else {
        this.getsavefields("update");
      }
    }

    //this.dialogRef.close(this.form.value);
  }

  activatetags() {
    let objEmail = this.tagifyService.get("emailsubject");
    let stremail = objEmail.DOM.input.textContent;
    for (let x = 0; x < objEmail.value.length; x++) {
      if (stremail.indexOf(objEmail.value[x].value) !== -1) {
        if (objEmail.value[x].value.indexOf("[") !== -1) {
        } else {
          $(document)
            .find(".tagify__tag-text")
            .each(function (index) {
              if (x == index) {
                $(this).html("[[" + objEmail.value[x].value + "]]");
              }
            });
        }
      }
    }
  }

  getsavefields(formmode) {
    this.activatetags();
    if (this.tabSelected == 0) {
      localStorage.setItem(
        "emailsubject",
        this.tagifyService.get("emailsubject").DOM.input.textContent
      );
      localStorage.setItem(
        "emailcontent",
        tinymce.get("qf_appr_cust_email").getContent({ format: "html" })
      );
    }
    this.savedata = {
      beginid: this.beginid,
      workflowid: this.form.value.workflowid,
      formid: this.form.value.formid,
      nodeid: this.form.value.nodeid,
      nodetype: this.nodetype,
      emailsubject: localStorage.getItem("emailsubject"),
      emailcontent: localStorage.getItem("emailcontent"),
      sendername: this.form.value.sendername,
      replyto: this.form.value.replyto,
      erecipient: this.form.value.erecipient,
      ispdfattach: this.form.value.ispdfattach,
      ispdfattachtype:
        this.ispdfattach == true
          ? this.ispdfattachapi == false
            ? "default"
            : "template"
          : "false",
      ishiddenfields: this.form.value.ishiddenfields,
      senderemail: this.form.value.senderemail,
      status: true,
      userid: this.api.getUserId(),
    };
    switch (formmode) {
      case "save":
        this.api.saveappRpt(this.savedata).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Approval Report saved successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Approval Report failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
        break;
      case "update":
        this.api.updateappRpt(this.savedata, this.savedata.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Approval Report updated successfully",
                "Success"
              );
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("Approval Report failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
        break;
      default:
        break;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
