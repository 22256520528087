import {
  APP_ID,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MatRadioChange } from "@angular/material/radio";
@Component({
  selector: "app-mergebranches",
  templateUrl: "./mergebranches.component.html",
  styleUrls: ["./mergebranches.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MergebranchesComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  panelOpenState: boolean = false;
  ruleid = 0;
  begindetails: any;
  data: any;
  ispublished: boolean = false;
  formname: string;
  branchdata: any = "all";

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private dialogRef: MatDialogRef<MergebranchesComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      nodeid,
      nodetype,
      workflowid,
      workflowname,
      workflowstage,
      branchdata,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.ispublished = ispublished;
    this.formname = localStorage.getItem("formname");
    this.branchdata = branchdata == "" ? "all" : branchdata;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      branchdata: [branchdata],
      ispublished: [ispublished],
    });
    //if (this.workflowstage != "") {
    this.form.controls.workflowstage.disable();
    //}
  }

  ngOnInit(): void {
    if (this.description === "mergebranches") {
      this.description = "Merge Branches";
    }
  }

  radioChange(event: MatRadioChange) {
    this.branchdata = event.value;
  }

  saveform(rulename, formstate) {
    if (rulename == "mergebranches") {
      let validform = true;
      if (this.form.value.workflowname == "") {
        validform = false;
      }

      if (validform) {
        this.data = {
          beginid: this.beginid,
          workflowid: this.form.value.workflowid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formid: this.form.value.formid,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          status: true,
          branchdata: this.branchdata,
          userid: this.api.getUserId(),
        };
        if (this.beginid === 0) {
          //save
          this.api.saveMergeBranches(this.data).subscribe(
            (res: any) => {
              this.ngxService.stop();
              if (res && Number(res.responseCode) === 201) {
                this.toastr.success(
                  "Merge Branches saved successfully",
                  "Success"
                );
                this.dialogRef.close({ cancel: false, fetch: true });
              } else {
                this.toastr.error("Merge Branches failed! ", "Error!");
              }
              this.ngxService.stop();
            },
            (err) => {
              this.ngxService.stop();
              console.log(err);
            }
          );
        } else {
          //update

          this.api.updateMergeBranches(this.data, this.data.beginid).subscribe(
            (res: any) => {
              this.ngxService.stop();
              if (res && Number(res.responseCode) === 201) {
                this.toastr.success(
                  "Merge Branches Updated successfully",
                  "Success"
                );
                this.dialogRef.close({ cancel: false, fetch: true });
              } else {
                this.toastr.error("Merge Branches failed! ", "Error!");
              }
              this.ngxService.stop();
            },
            (err) => {
              this.ngxService.stop();
              console.log(err);
            }
          );
        }
        this.dialogRef.close(this.form.value);
      } else {
        this.toastr.error("Please fill all mandatory fields!", "Error!");
      }
    }
    //this.dialogRef.close({ cancel: false, fetch: true });
  }

  close() {
    this.dialogRef.close();
  }
}
