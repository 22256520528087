import {
  APP_ID,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-endflow",
  templateUrl: "./endflow.component.html",
  styleUrls: ["./endflow.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EndflowComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  panelOpenState: boolean = false;
  ruleid = 0;
  begindetails: any;
  data: any;
  ispublished: boolean = false;
  formname: string;

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private dialogRef: MatDialogRef<EndflowComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.formname = localStorage.getItem("formname");
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      ispublished: [ispublished],
    });
    this.form.controls.workflowstage.disable();
  }

  ngOnInit(): void {
    if (this.description === "endflow") {
      this.description = "End Flow";
    }
  }

  saveform(rulename, formstate) {
    if (rulename == "endflow") {
      if (this.beginid === 0 && formstate == "save") {
        this.data = {
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          status: true,
          userid: this.api.getUserId(),
        };
      } else {
        this.data = {
          beginid: this.beginid,
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          status: true,
          userid: this.api.getUserId(),
        };
      }

      if (this.beginid === 0) {
        //save
        this.api.saveEndFlow(this.data).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("End Flow saved successfully", "Success");
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("End Flow failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      } else {
        //update
        this.api.updateEndFlow(this.data, this.data.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("End Flow Updated successfully", "Success");
              this.dialogRef.close({ cancel: false, fetch: true });
            } else {
              this.toastr.error("End Flow failed! ", "Error!");
            }
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
      }
    }
    //this.dialogRef.close({ cancel: false, fetch: true });
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
